@import "../node_modules/@syncfusion/ej2-react-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-notifications/styles/material.css";

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  /* 스크롤바 숨김 - IE */
  -ms-overflow-style: none;
}

/* 스크롤바 숨김 chromium, firefox 기반*/
::-webkit-scrollbar {
  display: none;
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropzone {
  margin-top: 1%;
  min-height: 100px !important;
  height: 130px !important;
}
.dropzone-text {
  font-size: 1.2rem !important;
}

.e-grid {
  cursor: default;
}

.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(33, 33, 33, 0.7);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .message {
  margin-top: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 5px black;
  font-size: large;
}

.e-grid .e-headercell {
  height: 35px;
}

.e-grid .e-rowcell {
  font-size: 14px;
}

.e-ddl.e-input-group.e-control-wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
}

.MuiChip-root .MuiChip-label {
  font-size: 14px;
}

textarea {
  border: 1px solid #dcdfe3;
  outline-color: #dcdfe3;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day {
  color: #971b2f;
  border: 1px solid #971b2f;
}

.e-calendar .e-content td:hover span.e-day {
  color: #971b2f;
  border: 1px solid #971b2f;
}

.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
  font-size: 1rem;
}

.e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover
  span.e-day {
  background-color: #971b2f;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-today:focus span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:focus span.e-day,
.e-bigger.e-small
  .e-calendar
  .e-content
  td.e-focused-date.e-today:hover
  span.e-day,
.e-bigger.e-small
  .e-calendar
  .e-content
  td.e-focused-date.e-today:focus
  span.e-day {
  background-color: #971b2f;
  color: #ffffff;
  border: 1px solid #971b2f;
}

.e-daterangepicker.e-popup
  .e-calendar
  .e-end-date.e-selected.e-range-hover
  span.e-day {
  background-color: #971b2f;
}

.e-daterangepicker.e-popup .e-footer .e-btn.e-flat,
.e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat {
  color: #000000;
}

.e-date-range-wrapper .e-input-group-icon.e-icons.e-active {
  color: #971b2f;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after {
  background: #971b2f;
}

a:hover {
  color: #4b4f5a !important;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  color: #718093;
  font-family: "Noto sans CJK KR";
  font-size: 15px;
  margin-bottom: 10px;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #3d4861;
  font-family: "Noto sans CJK KR";
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.e-tab .e-tab-header .e-indicator {
  background: #252933;
}

.css-1cb6ucr-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #971b2f !important;
  font-family: "Noto sans CJK KR";
  font-size: 15px;
  font-weight: 600;
  font-size: 1rem;
}

.css-uhtc8v.Mui-selected {
  color: #971b2f !important;
  font-family: "Noto sans CJK KR";
  font-size: 15px;
  font-weight: 600;
  font-size: 1rem;
}

.css-19n7zns {
  background-color: #971b2f !important;
}

.css-qx8hqu-MuiTabs-indicator {
  background-color: #971b2f !important;
}
.css-uhtc8v {
  font-size: 1rem !important;
}

.css-1cb6ucr-MuiButtonBase-root-MuiTab-root {
  font-size: 1rem !important;
  font-family: "Noto sans CJK KR" !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0rem !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #ffffff !important;
}

.css-1nx9zqk-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}

/* 라디오 버튼 */
.css-1m9pwf3.Mui-checked {
  color: #000000 !important;
}

.css-1qeilas-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #000000 !important;
}

.e-daterangepicker.e-popup {
  max-width: 100%;
}

.css-1xa2ayc-MuiCircularProgress-root {
  color: black !important;
}

.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.e-checkbox-wrapper .e-checkbox:hover + .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-checkbox:hover + .e-frame.e-check,
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #971b2f;
}

.e-message .e-msg-content {
  font-size: 1rem;
}

.e-message.e-outlined .e-msg-icon {
  color: #3d4861;
}

.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day {
  color: "#971B2F";
}

.e-schedule .e-text-center {
  text-align: left;
}

.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day {
  color: #000000;
}

.e-schedule .e-month-view .e-current-date .e-date-header {
  background: #971b2f;
}

.e-schedule .e-content-wrap,
.e-schedule .e-scroll-container {
  overflow: hidden;
}

.month-schedule-calendar .e-content-wrap,
.month-schedule-calendar .e-scroll-container {
  overflow: scroll !important;
}

.daily-schedule-calendar .e-content-wrap,
.daily-schedule-calendar .e-scroll-container {
  overflow: scroll !important;
}

.e-appointment {
  box-shadow: none !important;
}

.swal2-container {
  z-index: 100000;
}

.e-work-cells.e-work-days.raffleperiod {
  background-color: #fffbdf;
}

.e-work-cells.raffleperiod {
  background-color: #fffbdf !important;
}

.testClass {
  float: right;
  margin-top: -25px;
  color: #cc1f3b;
}

.css-tjmnll.Mui-checked {
  color: #000000 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #dcdfe3 !important;
}

.e-input-group.e-error,
.e-input-group.e-control-wrapper.e-error,
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 7.5px 4px 7.5px 15px;
}

.e-ddl.e-input-group.e-control-wrapper .e-input {
  font-family: "Noto sans CJK KR";
  font-size: 16px;
}

.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group input.e-input:focus,
.e-input-group.e-control-wrapper input.e-input:focus {
  padding-left: 5px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  font-family: "Noto sans CJK KR";
}

.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input:focus {
  text-align: center;
}

.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 7.5px 4px 7.5px 15px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 4px 7.5px 15px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root {
  padding: 0px !important;
}

.css-1fh2hol {
  border-radius: 8px !important;
}
.centered-placeholder::placeholder {
  text-align: center;
}

.css-suoyio-MuiInputBase-root-MuiInput-root:focus {
  color: #000000;
}

.MuiInputBase-root
  .MuiInput-root
  .MuiInput-underline
  .MuiInputBase-colorPrimary
  .css-suoyio-MuiInputBase-root-MuiInput-root:focus {
  color: #000000;
}

.e-ddl.e-input-group input.e-input::placeholder {
  font-family: "Noto sans CJK KR";
  font-size: "20px";
}

.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label {
  font-family: "Noto sans CJK KR";
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:focus,
.e-pager .e-currentitem:active {
  background-color: #971b2f !important;
  color: #ffffff !important;
}
.e-ddl.e-input-group .e-input-value,
.e-ddl.e-input-group .e-input-value:focus {
  text-align: center;
}

.droppable {
  top: auto !important;
  left: auto !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.daily-schedule-calendar .e-schedule-toolbar-container {
  display: none !important;
}

.e-grid .e-emptyrow td {
  text-align: center !important;
}

.e-schedule .e-appointment {
  width: 150px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
